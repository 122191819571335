import BatchListSelecterComponent from "@/components/batch/common/BatchListSelecter/BatchListSelecter.component";
import PreSummary from "@/components/batch/convert/preSummary/PreSummary.component";
import { BatchAction, CustomBatch } from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { ungroup, useEquivalentUsableWeight } from "@/utils/convert.utils";
import { mathJs, sumArray, sumObjArrayBy } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./BatchConvertProduct.template.vue";

@Component({
  mixins: [Template],
  components: {
    "batch-select": BatchListSelecterComponent,
    PreSummary
  }
})
export default class BatchConvertProductComponent extends Vue {
  public get includeMarijuana() {
    return !!ungroup(this.dataClone!.batches).find(
      (batch: CustomBatch) => !!batch.isMarijuana
    );
  }
  @Prop() public data!: BatchAction<CustomBatch>;
  @Prop() public currentBuildConversions!: CustomBatch[];
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  public dataClone: BatchAction<CustomBatch> | null = null;
  public listData: BatchAction<CustomBatch> | null = null;
  public showForm: boolean = false;
  public isValid: boolean = true;
  public dataKey = 0;
  public total: {
    countable: number;
    weighable: string;
    usableWeight: string;
  } = {
    countable: 0,
    weighable: "0",
    usableWeight: "0"
  };

  @Watch("data", { deep: true })
  public reloadData() {
    this.setBatchSelection(this.data);
  }

  public convert() {
    this.$emit(
      "setToConvert",
      ungroup(this.dataClone!.batches).map(b => ({
        ...b,
        room_quantity: +b.room_quantity_editable
      }))
    );
  }

  public totalBatch(batch: string, index: string) {
    return sumArray(this.dataClone!.batches[batch], index);
  }

  public updateTotals() {
    if (this.showForm) {
      const totals: CustomBatch[] = ungroup(this.dataClone!.batches);
      const weighable = sumObjArrayBy(totals, (t: CustomBatch) =>
        t.unit !== PRODUCT_UNIT.UNIT_READABLE ? +t.room_quantity_editable : 0
      );
      this.total = {
        countable: sumObjArrayBy(totals, (t: CustomBatch) =>
          t.unit === PRODUCT_UNIT.UNIT_READABLE ? +t.room_quantity_editable : 0
        ),
        weighable: `${weighable} ${PRODUCT_UNIT.GRAMS}`,
        usableWeight: `${mathJs.add!(
          totals.reduce((acc: math.BigNumber, t: CustomBatch) => {
            acc = mathJs.add!(
              acc,
              t.usable_weight_value
                ? mathJs.multiply!(
                    +t.room_quantity_editable,
                    useEquivalentUsableWeight(t)
                  )
                : 0
            ) as math.BigNumber;
            return acc;
          }, mathJs.bignumber!(0)),
          weighable
        )} ${PRODUCT_UNIT.GRAMS}`
      };
    }
  }

  public created() {
    this.setBatchSelection(this.data);
  }

  public onInput() {
    this.isValid = (this.$refs.form as Vue & {
      validate: () => boolean;
    }).validate();
    if (this.isValid) {
      this.updateTotals();
    }
  }

  public updateSelected(batches: { [batchID: string]: CustomBatch[] }) {
    this.dataClone = cloneDeep(this.data);
    this.showForm = false;
    Object.values(batches).forEach(batch => {
      if (batch[0].selected) {
        this.showForm = true;
      } else {
        delete this.dataClone!.batches[batch[0].batch_uid!];
      }
    });
    this.dataKey = Math.random();
    this.addValidationRules();
    this.updateTotals();
  }

  private setBatchSelection(data: BatchAction<CustomBatch>) {
    this.listData = cloneDeep(data);
    Object.values(data.batches).forEach(batch => {
      this.listData!.batches[batch[0].batch_uid!] = batch.map(b => ({
        ...b,
        selected: false
      }));
    });
    this.showForm = false;
  }

  /**
   * Validation rules are added through this method
   * since an array of functions cannot be passed
   * through route change (JSON.stringify returns null)
   */
  private addValidationRules() {
    if (this.dataClone && this.dataClone.batches) {
      Object.values(this.dataClone.batches).forEach(batchId =>
        batchId.forEach(
          (batch, index) =>
            (batch.rules = [
              (v: string) =>
                (+v >= 0 &&
                  +v <=
                    +this.dataClone!.batches[batch.batch_uid!][index]
                      .room_quantity!) ||
                this.$t("batch_combine.limit").toString()
            ])
        )
      );
    }
  }
}
