import { CustomBatch } from "@/interfaces/batch";
import { Product } from "@/interfaces/product";
import { InventoryLocation } from "@/interfaces/reservation";
import { Strain } from "@/interfaces/strain";
import { MetrcData, MetrcItem } from "@/interfaces/traceability";

export interface ConvertItem {
  origin: CustomBatch[] | null;
  destination?: ResultItem;
  options: OptionsConvert & { metrc_item?: MetrcItem | null };
  result?: ResultItem;
  biotrack_traceability_id?: string;
}

export interface OptionsConvert {
  tracking_id: string | null;
  auto_print: boolean;
  usable_weight_value: number | null;
  unit_measure: string | null;
  waste: string | null;
  pass_through_qa_results: boolean;
  add_waste_cost_to_total: boolean;
}

export interface PayloadConvert {
  origin: Array<{
    batch_fraction_uid?: string;
    quantity: number;
  }>;
  destination: ResultItem;
  options: OptionsConvert & { metrc_item: string | null };
}

export interface Reservation {
  reservation: ReservationData;
  operation_uid: string;
}

export interface ReservationData {
  id: number;
  location_id: number;
  user_id: number;
  reservation_status_type_id: number;
  reservation_status_type: string;
  reservation_items: ReservationItem[];
}

export interface ReservationItem {
  id: number;
  reservation_id: number;
  batch_fraction_uid: string;
  quantity_value: number;
  created_at: string;
  updated_at: string;
  quantity_unit: string;
  inventory_location_id: number;
  quantity: string;
}

export interface ResultItem {
  product?: Product | null;
  strain?: Strain | null;
  inventory_locations: Array<{
    inventory_location_id: number | null;
    quantity: number | null;
  }>;
  sku?: string;
  batch_uid?: string;
  quantity_formatted?: string;
  room?: InventoryLocation;
  biotrack_traceability_id?: string;
}

export const convertItemDefault: ConvertItem = {
  origin: null,
  destination: {
    product: null,
    strain: null,
    inventory_locations: [
      {
        inventory_location_id: null,
        quantity: null
      }
    ]
  },
  options: {
    tracking_id: "",
    auto_print: false, // revisar
    metrc_item: null,
    usable_weight_value: null,
    unit_measure: null,
    waste: "",
    pass_through_qa_results: false,
    add_waste_cost_to_total: false
  }
};
export interface ConvertionResult {
  id: number;
  operation_uid: string;
  reference: string;
  status: string;
  options: OptionsConvert;
  created_by: number;
  updated_by: number;
  account_id: number;
  created_at: string;
  updated_at: string;
  origin: Origin[];
  result: Result;
  fractions: Fraction[];
}

interface Fraction {
  batch_fraction_uid: string;
  inventory_location_id: number;
  inventory_location: Inventorylocation;
  quantity: string;
  unit: string;
}

interface Result {
  biotrack_traceability_id?: string;
  sku: string;
  batch_uid: string;
}

interface Origin {
  sku: string;
  batch_uid: string;
  batch_fraction_uid: string;
  inventory_location_id: number;
  inventory_location: Inventorylocation;
  quantity: string;
  unit: string;
}

interface Inventorylocation {
  id: number;
  name: string;
}
