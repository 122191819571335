import { render, staticRenderFns } from "./SummaryConvert.template.vue?vue&type=template&id=95380992&scoped=true&"
var script = {}
import style0 from "./SummaryConvert.template.vue?vue&type=style&index=0&id=95380992&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95380992",
  null
  
)

export default component.exports