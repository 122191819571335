import BatchConvertCreateComponent from "@/components/batch/convert/convertCreateBatch/BatchConvertCreate.component";
import BatchConvertProductComponent from "@/components/batch/convert/convertProduct/BatchConvertProduct.component";
import {
  ConvertionResult,
  ConvertItem,
  Reservation
} from "@/components/batch/convert/model/batchConvert.model";
import SummaryConvertComponent from "@/components/batch/convert/summaryConvert/SummaryConvert.component";
import LoadingWindowComponent from "@/components/metrc/loadingWindow/loadingWindow.component";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import InventoryLabelListComponent from "@/components/sharedComponents/print/inventoryLabelList/InventoryLabelList.component";
import { policyList } from "@/enums/permissions";
import { BatchAction, CustomBatch, InventoryBatch } from "@/interfaces/batch";
import { Product } from "@/interfaces/product";
import { EventBus } from "@/internal";
import { metrcEnabled } from "@/router.utils";
import { batchOperationsService } from "@/services/batchOperations.service";
import { productService } from "@/services/product.service";
import { PageNavAction } from "@/types/types";
import { getParentSKU, mapBatchesToAction } from "@/utils/batch-actions.utils";
import { CallbackPromise } from "helix-vue-components";
import { ActionsSubheaderComponent, BooleanCheck } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import keys from "lodash/keys";
import last from "lodash/last";
import startsWith from "lodash/startsWith";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import QuantitiesReservationComponent from "../common/QuantitiesReservation/QuantitiesReservation.component";
import Template from "./BatchConvert.template.vue";
@Component({
  mixins: [Template],
  components: {
    "set-quantity-convert": BatchConvertProductComponent,
    "set-destination-product": BatchConvertCreateComponent,
    reservation: QuantitiesReservationComponent,
    "summary-convert": SummaryConvertComponent
  }
})
export default class BatchConvertComponent extends Vue {
  public get currentBuildConversions() {
    return this.conversions.map(item => ({
      name: item.destination!.product!.name,
      quantity: item.destination!.inventory_locations[0].quantity,
      batch_uid: item.destination!.batch_uid,
      unit: item.destination!.product!.requires_weighing
        ? item.destination!.product!.unit
        : "u"
    }));
  }
  public get getData() {
    return this.data;
  }
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Getter("hasPermission", { namespace: "PermissionsModule" })
  public hasPermission!: BooleanCheck;
  @Getter("products", { namespace: "ProductModule" })
  public products!: Product[];
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  @Action("loadInventoryProducts", { namespace: "ProductModule" })
  public loadInventoryProducts!: CallbackPromise<void>;
  public data: BatchAction<CustomBatch> | null = null;
  /** Original batches data
   * Once set in created lifecycle it must not change
   * since it's the only source of truth.
   */
  public batches: InventoryBatch[] = [];
  public canReserve = true;
  public canPrintLabels = false;
  public reservationData: Reservation | null = null;
  public resultConversions: ConvertionResult[] | null = null;
  public currentOrigin: CustomBatch[] | null = null;
  public step: number = 0;
  public title: string = "";
  public formattedBatches: BatchAction<CustomBatch> | null = null;
  public loading = false;
  public conversions: ConvertItem[] = [];
  public hasMetrc = metrcEnabled();

  public back() {
    if (this.conversions.length && this.step < 3) {
      this.$modals
        .load(
          ConfirmModalComponent,
          {
            size: "small"
          },
          {
            modalData: {
              title: "batch_convert.title_warning",
              body: "batch_convert.msg_abandon",
              icon: "fal fa-exclamation-triangle"
            }
          }
        )
        .then(
          () => {
            this.$router.back();
          },
          () => {
            /** Nothing to do */
          }
        );
    } else {
      this.$router.back();
    }
  }

  public async created() {
    this.setPageNav({
      title: `${this.$t("batch_convert.title_convert").toString()} ${this.$t(
        "batch_convert.convert_batch"
      ).toString()}`,
      rightActions: {
        generalActions: () => [
          {
            visibleCondition: () => !this.step,
            action: this.reserveQuantities,
            buttonText: "batch_convert.reserve",
            vuetifyProps: () => ({
              disabled: this.loading && !this.canReserve,
              loading: this.loading
            })
          },
          {
            visibleCondition: () => this.step === 3,
            action: () => this.back(),
            buttonText: "batches.back_to_inventory",
            vuetifyProps: () => ({
              disabled: this.loading
            })
          },
          {
            visibleCondition: () => [1, 2].includes(this.step),
            action: () => {
              this.step = 1;
              this.finishConvert();
            },
            buttonText: "batches.finish",
            vuetifyProps: () => ({
              disabled: this.loading,
              loading: this.loading
            })
          }
        ]
      },
      leftActions: {
        component: ActionsSubheaderComponent,
        props: {
          generalActions: [
            {
              visibleCondition: () => !this.step,
              icon: "fal fa-chevron-left",
              action: this.back
            }
          ]
        }
      }
    });

    this.batches = JSON.parse(this.$route.params.batches);
    await this.checkForProduct().then(data => {
      this.batches = data;
      this.formattedBatches = mapBatchesToAction(this.batches);
    });
    this.canPrintLabels = this.hasPermission(policyList.printBatchLabels);
    EventBus.$on("deleteItemToConvert", (index: number) => {
      this.updateQuantitiesInUse(this.conversions[index].origin!, false);
      this.conversions.splice(index, 1);
    });
  }

  public formData() {
    const rawBatchKeys = keys(this.formattedBatches!.batches);
    this.data = cloneDeep(this.formattedBatches);
    this.reservationData!.reservation.reservation_items.forEach(item => {
      const batchUId = rawBatchKeys.find(k =>
        startsWith(item.batch_fraction_uid, k)
      );
      if (batchUId) {
        const inFormDataIndex = this.data!.batches[batchUId].findIndex(
          r => r.room_id === item.inventory_location_id
        );
        if (inFormDataIndex !== -1) {
          this.data!.batches[batchUId][inFormDataIndex] = {
            ...this.data!.batches[batchUId][inFormDataIndex],
            room_quantity: item.quantity_value,
            batch_fraction_uid: item.batch_fraction_uid
          };
        }
      }
    });
  }

  public updateReservationData(e: {
    reservation: BatchAction<CustomBatch>;
    valid: boolean;
  }) {
    this.formattedBatches = e.reservation;
    this.canReserve = e.valid;
  }

  public async reserveQuantities() {
    this.loading = true;
    this.reservationData = await batchOperationsService.createOperation(
      this.formattedBatches!,
      "convert"
    );
    if (this.reservationData) {
      this.formData();
      this.step = 1;
    }
    this.loading = false;
  }

  public setToConvert(data: CustomBatch[]) {
    this.currentOrigin = data.filter(b => b.room_quantity_editable);
    this.step = 2;
  }

  @Watch("products")
  public formatedProducts() {
    if (this.products.length) {
      const currentBatch = last(this.conversions)!.destination!.batch_uid;
      EventBus.$emit("print", {
        component: InventoryLabelListComponent,
        props: {
          batches: (this.products[0].batches as InventoryBatch[]).filter(
            (item: InventoryBatch) => item.batch_uid === currentBatch
          ),
          labelType: "INVENTORY"
        }
      });
    }
  }

  public async setNewConvertion(data: ConvertItem) {
    this.resultConversions = await batchOperationsService.resultConvert(
      this.reservationData!.operation_uid
    );
    if (
      this.bioTrackTraceEnabled &&
      last(this.resultConversions)!.result!.biotrack_traceability_id !== null
    ) {
      data.destination!.batch_uid = last(
        this.resultConversions
      )!.result!.biotrack_traceability_id;
    } else {
      data.destination!.batch_uid = last(
        this.resultConversions
      )!.result!.batch_uid;
    }
    return {
      ...data,
      origin: this.currentOrigin
    };
  }

  public async onAddtoConvet(data: ConvertItem) {
    this.loading = true;
    if (this.hasMetrc) {
      this.$modals
        .load(LoadingWindowComponent, {
          closable: false,
          size: "fit",
          positionY: "center",
          positionX: "center",
          backdrop: true
        })
        .catch(() => {
          // nothing to do
        });
    }
    const res = await batchOperationsService.forwardConvert(
      this.reservationData!.operation_uid,
      [
        {
          ...data,
          origin: this.currentOrigin
        }
      ],
      this.hasMetrc
    );
    if (res && res.errors) {
      EventBus.$emit("mtrcLoadingEvent", {
        show: true,
        errorList: res.errors || []
      });
      this.loading = false;
      return;
    } else {
      EventBus.$emit("mtrcLoadingEvent", {
        show: false
      });
    }
    if (res) {
      this.updateQuantitiesInUse(this.currentOrigin!, true);
      this.conversions.push(await this.setNewConvertion(data));
      if (this.canPrintLabels && data.options!.auto_print) {
        const queryfilter = {
          "q[batches.batch_uid_contains]": data.destination!.batch_uid
        };
        productService.setQuery(queryfilter);
        this.loadInventoryProducts();
      }
      if (res.stage.operation_stage_type_name !== "FINISHED") {
        this.step = 1;
      } else {
        await this.finishConvert();
      }
    }
    this.loading = false;
  }

  protected async checkForProduct() {
    return Promise.all(
      this.batches.map(async b => {
        if (!b.product) {
          b.product = await productService.findBySkuId(getParentSKU(b.sku));
        }
        return b;
      })
    );
  }

  private updateQuantitiesInUse(currentData: CustomBatch[], pull: boolean) {
    const calc = pull ? -1 : 1;
    currentData.forEach(batch => {
      const index = this.data!.batches[batch.batch_uid!].findIndex(
        b => b.batch_fraction_uid === batch.batch_fraction_uid
      );
      this.data!.batches[batch.batch_uid!][index].room_quantity =
        +this.data!.batches[batch.batch_uid!][index].room_quantity +
        calc * batch.room_quantity;
      this.data!.batches[batch.batch_uid!][index].room_quantity_editable = +this
        .data!.batches[batch.batch_uid!][index].room_quantity;
    });
  }
  private async finishConvert() {
    this.loading = true;
    const responseConvert = await batchOperationsService.finishConvert(
      this.reservationData!.operation_uid
    );
    if (this.conversions.length) {
      if (responseConvert) {
        this.step = 3;
      }
    } else {
      this.back();
    }

    this.loading = false;
  }
}
