import { CustomBatch } from "@/interfaces/batch";
import { EventBus } from "@/internal";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./PreSummary.template.vue";

@Component({
  mixins: [Template]
})
export default class PreSummaryComponent extends Vue {
  @Prop() public currentBuildConversions!: CustomBatch[];
  @Prop() public removable!: boolean;
  public onDeleteItem(index: number) {
    EventBus.$emit("deleteItemToConvert", index);
  }
}
