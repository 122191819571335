import BatchListSelecterComponent from "@/components/batch/common/BatchListSelecter/BatchListSelecter.component";
import {
  ConvertItem,
  convertItemDefault
} from "@/components/batch/convert/model/batchConvert.model";
import PreSummary from "@/components/batch/convert/preSummary/PreSummary.component";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import NextTagComponent from "@/components/sharedComponents/nextTag/NextTag.component";
import { CustomBatch } from "@/interfaces/batch";
import { PRODUCT_UNIT } from "@/interfaces/batchTransferManager";
import { PriceGroup, Product } from "@/interfaces/product";
import { Room } from "@/interfaces/room";
import { Strain } from "@/interfaces/strain";
import { MetrcItem } from "@/interfaces/traceability";
import { metrcEnabled } from "@/router.utils";
import { batchLevelService } from "@/services/batchLevel.service";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import { strainService } from "@/services/strain.service";
import { Callback } from "@/types/types";
import { useEquivalentUsableWeight } from "@/utils/convert.utils";
import { mathJs } from "@/utils/math.utils";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import groupBy from "lodash/groupBy";
import { Validator } from "vee-validate";
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./BatchConvertCreate.template.vue";

@Component({
  mixins: [Template],
  components: {
    "batch-select": BatchListSelecterComponent,
    PreSummary,
    NextTagComponent
  }
})
export default class BatchConvertCreateComponent extends Vue {
  public get requireSecondaryId(): boolean {
    return !!(
      this.metrcEnabled &&
      this.origin &&
      this.origin.find(b => !!b.isMarijuana)
    );
  }

  public get requireUsableWeighing() {
    const currentProduct =
      this.currentConvert.destination &&
      this.currentConvert.destination.product;
    return (
      currentProduct &&
      currentProduct.marijuana &&
      !currentProduct.requires_weighing
    );
  }

  public get currentUnitConvertion() {
    return (this.currentConvert.options.unit_measure ||
      PRODUCT_UNIT.GRAMS) as PRODUCT_UNIT;
  }

  public get noMarijuanaUnitConvertion() {
    if (this.requireUsableWeighing) {
      return PRODUCT_UNIT.UNIT_READABLE;
    }

    return this.currentUnitConvertion;
  }

  public get headerInfo() {
    const currentUsableWeight =
      (this.currentConvert.options.unit_measure &&
        this.currentConvert.options.usable_weight_value) ||
      1;
    this.available = mathJs.round!(
      this.origin.reduce((acc: math.BigNumber, data: CustomBatch) => {
        acc = mathJs.add!(
          acc,
          data.isMarijuana
            ? this.getEquivalentUsableWight(data)
            : mathJs.bignumber!(0)
        ) as math.BigNumber;
        return acc;
      }, mathJs.bignumber!(0)),
      2
    );

    this.assigned = mathJs.bignumber!(
      (this.currentConvert.destination!.inventory_locations![0].quantity &&
        mathJs.multiply!(
          this.currentConvert.destination!.inventory_locations![0].quantity,
          currentUsableWeight
        )) ||
        0
    );
    this.applyAutoWaste();
    const assignedwithWaste = mathJs.add!(
      this.assigned,
      mathJs.bignumber!(this.currentConvert.options.waste! || 0)
    );
    this.withRemainig =
      !!this.currentConvert.destination!.inventory_locations![0].quantity &&
      +this.available !== +assignedwithWaste;

    return {
      available: `${this.available} ${this.currentUnitConvertion}`,
      assigned: `${this.verifyAssigned(assignedwithWaste as math.BigNumber)} ${
        this.currentUnitConvertion
      }`,
      remaining: `${mathJs.subtract!(this.available, assignedwithWaste)} ${
        this.currentUnitConvertion
      }`
    };
  }
  @Inject("$validator") public $validator!: Validator;
  public searchProductField: string = "";
  public searchMetrcField: string = "";
  public searchStrainField: string = "";
  public searchBatchLevelPrices: string = "";
  public isLoadingBatchLevelPrices: boolean = false;
  public isLoadingStrains: boolean = false;
  public isLoadingProduct: boolean = false;
  public isLoadingMetrc: boolean = false;
  public withRemainig: boolean = false;
  public assigned: math.BigNumber = mathJs.bignumber!(0);
  public available: math.BigNumber = mathJs.bignumber!(0);
  public autoWaste: boolean = false;
  public convertExceed: boolean = false;
  public currentConvert: ConvertItem = cloneDeep(convertItemDefault);
  public metrcList: MetrcItem[] = [];
  public defaultMetrcList: MetrcItem[] = [];
  public products: Product[] = [];
  public defaultProducts: Product[] = [];
  public batchLevels: PriceGroup[] = [];
  public isMarijuana: number = 0;
  public groupBy = groupBy;
  @Getter("rooms", { namespace: "ProductModule" })
  public rooms!: Room[];
  public strainItems: Strain[] = [];
  public defaultStrains: Strain[] = [];
  public weighableUnits: Array<{ label: string; value: PRODUCT_UNIT }> = [
    { label: "gr", value: PRODUCT_UNIT.GRAMS },
    { label: "mg", value: PRODUCT_UNIT.MG },
    { label: "oz", value: PRODUCT_UNIT.OZ }
  ];
  public metrcEnabled = metrcEnabled();
  @Prop() public loading!: boolean;
  @Prop({ default: false }) public canPrint!: boolean;
  @Prop() public currentBuildConversions!: CustomBatch[];
  @Prop() private origin!: CustomBatch[];

  private loadDebounce = debounce((value: string, getterItems: Callback) => {
    if (!value || value.length > 2) {
      getterItems(value);
    }
  }, 500);

  public getEquivalentUsableWight(data: CustomBatch): math.BigNumber {
    return mathJs.multiply!(
      useEquivalentUsableWeight(data, this.currentUnitConvertion),
      data.room_quantity
    ) as math.BigNumber;
  }

  public verifyAssigned(assignedwithWaste: math.BigNumber): math.BigNumber {
    if (!this.isMarijuana && this.origin[0].unit === "u") {
      return mathJs.bignumber!(0);
    }
    return assignedwithWaste;
  }

  public maxQuantityToCreate() {
    if (
      this.isMarijuana &&
      this.currentConvert.destination!.product &&
      this.currentConvert.destination!.product.marijuana &&
      !this.currentConvert.destination!.product.requires_weighing
    ) {
      return mathJs.floor!(
        +this.available / this.currentConvert.options.usable_weight_value!
      );
    } else {
      return null;
    }
  }

  @Watch("searchStrainField")
  public async searchStrain(strainName: string) {
    if (!this.currentConvert.destination!.strain) {
      this.loadDebounce(strainName, (val: string) => this.getStrains(val));
    }
  }

  @Watch("searchProductField")
  public searchData(productName: string) {
    this.loadDebounce(productName, this.getProducts);
  }

  @Watch("searchMetrcField")
  public searchMetrc(metrcName: string) {
    this.loadDebounce(metrcName, this.updateMetrcItemList);
  }

  public back() {
    this.$emit("back");
  }
  public async onCreate() {
    if (await this.$validator.validateAll("convert-batch")) {
      if (
        !this.currentConvert.destination!.product!.requires_weighing &&
        this.currentConvert.destination!.product!.marijuana &&
        ((this.currentConvert.options.usable_weight_value &&
          +this.currentConvert.destination!.product!.usable_weight_value! + 1 <
            +this.currentConvert.options.usable_weight_value) ||
          +this.currentConvert.destination!.product!.usable_weight_value! >
            +this.currentConvert.options.usable_weight_value! ||
          this.currentConvert.options.unit_measure !==
            this.currentConvert.destination!.product!.usable_weight_unit)
      ) {
        this.$modals
          .load(
            ConfirmModalComponent,
            {
              size: "small"
            },
            {
              modalData: {
                title: "batch_convert.title_warning",
                body: this.$t("batch_convert.usable_weight_warning_msg", {
                  newUsableWeight: `${
                    this.currentConvert.options.usable_weight_value
                  } ${this.currentConvert.options.unit_measure}`,
                  defaultUsableWeight: `${
                    this.currentConvert.destination!.product!
                      .usable_weight_value
                  } ${
                    this.currentConvert.destination!.product!.usable_weight_unit
                  }`
                }),
                icon: "fal fa-exclamation-triangle"
              }
            }
          )
          .then(
            () => {
              this.$emit("onAddtoConvet", this.currentConvert);
            },
            () => {
              /** Nothing to do */
            }
          );
      } else {
        this.$emit("onAddtoConvet", this.currentConvert);
      }
    }
  }

  public async changeProduct() {
    const selectedProduct: Product | null =
      (this.currentConvert.destination &&
        this.currentConvert.destination.product) ||
      null;
    this.currentConvert.options.usable_weight_value =
      (selectedProduct && selectedProduct.usable_weight_value) || null;
    this.currentConvert.options.unit_measure =
      (selectedProduct && selectedProduct.usable_weight_unit) ||
      (selectedProduct &&
        selectedProduct.requires_weighing &&
        selectedProduct.unit) ||
      (!selectedProduct && PRODUCT_UNIT.GRAMS) ||
      PRODUCT_UNIT.UNIT_READABLE;

    //  Sets strain field
    if (selectedProduct && selectedProduct.strain_id) {
      this.currentConvert.destination!.strain = selectedProduct.strain;
      this.strainItems = [selectedProduct.strain!];
    } else {
      this.currentConvert.destination!.strain = null;
    }
    // Sets product quantity field
    if (selectedProduct && selectedProduct.marijuana) {
      this.$nextTick(() => {
        this.currentConvert.destination!.inventory_locations![0].quantity = this.maxQuantityToCreate()!;
      });
    } else {
      this.currentConvert.destination!.inventory_locations![0].quantity = null;
    }
    this.currentConvert.destination!.inventory_locations[0].inventory_location_id = null;
    this.autoFillMetrcItem();
  }

  public applyAutoWaste() {
    if (this.autoWaste) {
      const currentUsableWeight =
        (this.currentConvert.options.unit_measure &&
          this.currentConvert.options.usable_weight_value) ||
        1;
      const assigned = mathJs.multiply!(
        +this.currentConvert.destination!.inventory_locations![0].quantity!,
        currentUsableWeight
      );
      const waste = mathJs.subtract!(
        this.available,
        mathJs.bignumber!(assigned)
      );
      this.convertExceed = waste < 0;
      this.currentConvert.options.waste = waste > 0 ? waste.toString() : "";
    }
  }

  public created() {
    this.isMarijuana = +!!this.origin.find(b => !!b.isMarijuana);
    this.getProducts();
    this.getStrains();
    this.getMetrcItemList();
  }

  public clearProduct() {
    this.metrcList = [];
    if (this.currentConvert.destination) {
      this.currentConvert.options.metrc_item = null;
    }
  }

  @Watch("searchBatchLevelPrices")
  protected searchPrices(batchLevelPrice: string) {
    this.loadDebounce(batchLevelPrice, this.getBatchLevelPrices);
  }

  private async getBatchLevelPrices(value: string = "") {
    this.isLoadingBatchLevelPrices = true;
    try {
      this.batchLevels = await batchLevelService.findByName(value);
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
    this.isLoadingBatchLevelPrices = false;
  }

  private async getMetrcItemList() {
    if (this.requireSecondaryId) {
      this.isLoadingMetrc = true;
      if (this.defaultMetrcList.length) {
        this.metrcList = this.defaultMetrcList;
        this.isLoadingMetrc = false;
        return;
      }
      this.metrcList = await productService.getMetrcList();
      if (!this.defaultMetrcList.length) {
        this.defaultMetrcList = cloneDeep(this.metrcList);
      }
      this.isLoadingMetrc = false;
    }
  }

  private async updateMetrcItemList(name?: string) {
    if (this.requireSecondaryId) {
      this.isLoadingMetrc = true;
      if (name) {
        this.metrcList = await productService.getMetrcList(name);
      } else {
        this.metrcList = this.defaultMetrcList;
      }
      this.isLoadingMetrc = false;
    }
  }

  private async getProducts(value: string = "") {
    if (
      this.currentConvert.destination &&
      this.currentConvert.destination.product
    ) {
      return;
    }
    try {
      if (!value && this.defaultProducts.length) {
        this.products = this.defaultProducts;
        return;
      }
      this.isLoadingProduct = true;
      const query = {
        sort: "name",
        per_page: 10,
        "q[marijuana_eq]": this.isMarijuana,
        "q[name_contains]": value
      };
      this.products = await productService.get(query);
      if (!value) {
        this.defaultProducts = cloneDeep(this.products);
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
    this.isLoadingProduct = false;
  }

  private async getStrains(search: string = "") {
    this.isLoadingStrains = true;
    if (!search && this.defaultStrains.length) {
      this.strainItems = this.defaultStrains;
      return;
    }
    this.strainItems = await strainService.get({
      "q[name_contains]": search,
      sort: "name",
      per_page: 10
    });

    if (!search) {
      this.defaultStrains = cloneDeep(this.strainItems);
    }
    this.isLoadingStrains = false;
  }

  private async autoFillMetrcItem() {
    if (
      this.requireSecondaryId &&
      this.currentConvert.destination &&
      this.currentConvert.destination.product
    ) {
      const metrcItem = await productService.getProductMetrc(
        this.currentConvert.destination.product.sku
      );

      if (metrcItem) {
        this.metrcList = [metrcItem];
        this.currentConvert.options.metrc_item = metrcItem;
        setTimeout(() => {
          this.loadDebounce.cancel();
        }, 300);
      } else {
        this.currentConvert.options.metrc_item = null;
      }
    }
  }
}
