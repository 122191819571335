import BatchListSelecterComponent from "@/components/batch/common/BatchListSelecter/BatchListSelecter.component";
import {
  ConvertionResult,
  ConvertItem,
  ResultItem
} from "@/components/batch/convert/model/batchConvert.model";
import PreSummary from "@/components/batch/convert/preSummary/PreSummary.component";
import { BatchAction, CustomBatch } from "@/interfaces/batch";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./SummaryConvert.template.vue";
interface PreSummaryData {
  name: string;
  quantity: number;
  batch_uid?: string;
  unit: "g" | "mg" | "oz" | "u" | null;
}
@Component({
  mixins: [Template],
  components: {
    "batch-select": BatchListSelecterComponent,
    PreSummary
  }
})
export default class SummaryConvertComponent extends Vue {
  @Prop() public conversions!: ConvertItem[];
  public summary: ConvertItem[] = [];
  public preSummaryData: PreSummaryData[] = [];
  @Prop() public data!: BatchAction<CustomBatch>;
  @Getter("hasBioTrackTraceIntegrations", { namespace: "AuthModule" })
  public hasBioTrackTraceIntegrations!: boolean;
  @Getter("bioTrackTraceEnabled", { namespace: "AuthModule" })
  public bioTrackTraceEnabled!: boolean;
  @Prop({ default: [] }) private resultConversions!: ConvertionResult[];
  public async mounted() {
    this.summary = this.conversions.reduce(
      (acc: ConvertItem[], item, index) => {
        const resultingconvertion = this.resultConversions[index];
        this.preSummaryData.push({
          name: item.destination!.product!.name,
          quantity: item.destination!.inventory_locations[0].quantity || 0,
          batch_uid:
            this.bioTrackTraceEnabled &&
            resultingconvertion.result!.biotrack_traceability_id !== null
              ? resultingconvertion.result!.biotrack_traceability_id
              : item.destination!.batch_uid || "",
          unit: item.destination!.product!.requires_weighing
            ? item.destination!.product!.unit
            : "u"
        });
        acc.push({
          origin: item.origin!.reduce((originAcc: CustomBatch[], i) => {
            if (i.batch_totals_editable && +i.room_quantity > 0) {
              originAcc.push({
                ...i,
                quantity: +resultingconvertion.origin!.find(
                  bo => bo.batch_fraction_uid === i.batch_fraction_uid
                )!.quantity
              });
            }
            return originAcc;
          }, []),
          result: {
            ...item.destination,
            batch_uid:
              this.bioTrackTraceEnabled &&
              resultingconvertion.result.biotrack_traceability_id !== null
                ? resultingconvertion.result.biotrack_traceability_id
                : resultingconvertion.result.batch_uid,
            room: resultingconvertion.fractions[0].inventory_location,
            quantity_formatted: `${parseFloat(
              resultingconvertion.fractions[0].quantity
            )} ${resultingconvertion.fractions[0].unit}`
          } as ResultItem,
          options: resultingconvertion.options
        });
        return acc;
      },
      []
    );
  }
}
